// src/Username.js

import React from "react";
import { useVimOSUser } from "./useVimOSUser";

export const Username = () => {
  const user = useVimOSUser();
  return (
    <div>
      {" "}
      Hi, this is{" "}
      {`${user.demographics.firstName} ${user.demographics.lastName}`}
    </div>
  );
};
