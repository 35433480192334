// src/useVimOSPatient.js

import { useContext, useState, useEffect } from "react";
import { VimOSContext } from "./VimOSContext";

export const useVimOSPatient = () => {
  const vimOS = useContext(VimOSContext);
  const [patient, setPatient] = useState(undefined);

  useEffect(() => {
    const patient = vimOS.ehr;
    console.log("vimOS.ehr", patient);
    // const cb = (newPatient) => setPatient(newPatient);
    // vimOS.ehr.subscribe("patient", cb);
    // return () => {
    //   vimOS.ehr.unsubscribe("patient", cb);
    // };
  }, [vimOS]);

  return patient;
};
