import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faA,
  faFaceSmile,
  faLink,
  faMicrophone,
  faPaperPlane,
  faPaperclip,
  faVideo,
  faXmark,
  faNotesMedical,
  faThumbsUp,
  faThumbsDown,
  faComment,
} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@mui/material'
import {
  useChronologicalSummaryRefreshMutation,
  useConditionAllergyRefreshMutation,
  useControlMedrecMutation,
  useDeleteBotMemoryMutation,
  useGetPatientBotSessionMutation,
  useMedicationRefreshMutation,
  useRefreshChoronologicalSummaryMutation,
  useUpdateMedrecBotStatusMutation,
} from '../../../api/api'
import { Field, Form, Formik } from 'formik'
import grainIcon from '../../../assets/img/icon-grain.png'
import expandIcon from '../../../assets/img/expand-more.png'
import frqLogo from '../../../assets/img/logo.png'

library.add(
  faPaperPlane,
  faA,
  faPaperclip,
  faLink,
  faMicrophone,
  faVideo,
  faFaceSmile,
  faXmark,
  faNotesMedical,
  faThumbsUp,
  faThumbsDown,
  faComment,
)

function ModalFooter({
  sendMessageBtn,
  patientMode,
  setPatientMode,
  setNotificationCounter,
  botStatus,
  routerLoading,
  handleNarrativeGuideNextStep,
  stopNarrative,
  setShowSchedulerModal,
  setBotStatus,
}) {
  const [updateColor, setUpdateColor] = useState({})

  const [refreshChoronologicalSummary] =
    useRefreshChoronologicalSummaryMutation()
  const [medicationRefresh, { isLoading }] = useMedicationRefreshMutation()
  const [conditionAllergyRefresh] = useConditionAllergyRefreshMutation()
  const [deleteBotMemory] = useDeleteBotMemoryMutation()
  const [
    controlMedrec,
    { isLoading: medrecControlIsloading, data: medrecControlData },
  ] = useControlMedrecMutation()

  const formRef = useRef(null)
  const inputRef = useRef(null)
  // const patientId = localStorage.getItem('frqPatientId')
  // const pharmacist_id = import.meta.env.VITE_REACT_APP_PHARMACIST_ID
  // const pharmacist_user_id = import.meta.env.VITE_REACT_APP_PHARMACIST_USER_ID
  // const patient_id = import.meta.env.VITE_REACT_APP_PATIENT_ID
  const pharmacist_id = '560fb893-d6fd-4f28-9c34-12c5d1f8072c'
  const pharmacist_user_id = '55445413-4556-4d10-b735-8b91a8a6812b'
  const patient_id = 'a2623098-97a8-4438-9147-8b636844f72a'
  
  // useHotkeys('ctrl+k', () => setPatientMode(!patientMode), [patientMode])

  const [updateMedrecBotStatus, { data: botStatusData }] =
    useUpdateMedrecBotStatusMutation()

  useEffect(() => {
    if (botStatusData) {
      setBotStatus(botStatusData.updated_value)
    }
  }, [botStatusData])

  useEffect(() => {
    const toolbarElements = document.getElementsByClassName('toolbar')

    const planeIcon = document.getElementById('message-submit-icon')
    const patientModeTitle =
      document.getElementsByClassName('patientmode-title')
    const tips = document.getElementById('shortcut-tips')
    if (patientMode) {
      setUpdateColor({ border: '1px solid #edd7f1' })

      if (toolbarElements.length > 0) {
        toolbarElements[0].style.borderTop = '1px solid #edd7f1'
      }

      if (planeIcon) {
        planeIcon.style.color = '#ba68c8'
      }
      if (patientModeTitle.length > 0) {
        patientModeTitle[0].children[0].style.color = '#ba68c8'
        patientModeTitle[0].children[1].style.color = '#ba68c8'
      }
      if (tips) {
        tips.style.color = '#ba68c8'
      }
    } else {
      setUpdateColor({})

      if (toolbarElements.length > 0) {
        toolbarElements[0].style.borderTop = '1px solid #f3f4f0'
      }

      if (planeIcon) {
        planeIcon.style.color = '#777'
      }
      if (patientModeTitle.length > 0) {
        patientModeTitle[0].children[0].style.color = '#727272'
        patientModeTitle[0].children[1].style.color = '#727272'
      }
      if (tips) {
        tips.style.color = '#727272'
      }
    }
  }, [patientMode])

  // const handleEmojiOpen = () => {
  //   setEmojiPicker(!emojiPicker)
  // }

  // const getEmoji = (emojiObj) => {
  //   const messageInput = inputRef.current

  //   const currentCursorPosition = messageInput.selectionStart

  //   const textBeforeCursorPosition = messageInput.value.substring(
  //     0,
  //     currentCursorPosition,
  //   )
  //   const textAfterCursorPosition = messageInput.value.substring(
  //     currentCursorPosition,
  //   )
  //   const updatedText =
  //     textBeforeCursorPosition + emojiObj.emoji + textAfterCursorPosition
  //   formRef.current.setFieldValue('message', updatedText)

  //   setEmojiPicker(false)
  // }

  const handleRefresh = () => {
    stopNarrative(false)
    try {
      refreshChoronologicalSummary({ patient_id: patient_id })
      // medicationRefresh({ patient_id: patientId })
      // conditionAllergyRefresh({ patient_id: patientId })
      setTimeout(() => {
        handleNarrativeGuideNextStep()
      }, 10000)
    } catch (e) {
      console.log(e)
    }
  }

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     // Check if the event target is not an input element
  //     if (
  //       event.ctrlKey &&
  //       event.key === 'k' &&
  //       event.target.tagName == 'INPUT'
  //     ) {
  //       setPatientMode(!patientMode)
  //     } else if (
  //       event.ctrlKey &&
  //       event.key === 'k' &&
  //       event.target.tagName !== 'INPUT'
  //     ) {
  //       setPatientMode(!patientMode)
  //     }
  //   }

  //   document.addEventListener('keydown', handleKeyDown)

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown)
  //   }
  // }, [patientMode])

  function hasPatientMention(text) {
    const regex = /@Patient/i
    return regex.test(text)
  }

  const handleKeyPress = (event) => {
    const messageCharacter = event.target.value

    if (hasPatientMention(messageCharacter)) {
      setPatientMode(true)
    } else {
      setPatientMode(false)
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        formRef.current.submitForm()
      } else if (event.ctrlKey && event.shiftKey && event.key === 'Enter') {
        setPatientMode(true)
        formRef.current.submitForm()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [formRef])

  const handleFocus = () => {
    setNotificationCounter(0)
  }

  const handleBlur = () => {
    setNotificationCounter(0)
  }

  const stopMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'STOPPED',
      })
    } catch (e) {
      console.log(e)
    }
  }
  const resumeMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'ACTIVE',
      })
    } catch (e) {
      console.log(e)
    }
  }
  const pauseMedrec = () => {
    try {
      updateMedrecBotStatus({
        patient_id: patient_id,
        pharmacist_id: pharmacist_id,
        session_state: 'INACTIVE',
      })
    } catch (e) {
      console.log(e)
    }
  }

  const deleteMedrec = () => {
    try {
      deleteBotMemory({
        pharmacist_id: pharmacist_id,
        patient_id: patient_id,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div
      className="quick-message-modal-footer"
      style={{ position: 'relative' }}
    >
      {botStatus == 'stop' ? (
        ''
      ) : (
        <>
          <div className="medrec-chat-footer">
            <button
              onClick={botStatus == 'INACTIVE' ? resumeMedrec : pauseMedrec}
              className={
                botStatus == 'INACTIVE' ? 'resume-medrec' : 'pause-medrec'
              }
              disabled={botStatus == 'STOPPED' ? true : false}
            >
              {botStatus == 'INACTIVE' ? 'Resume' : 'Pause'}
            </button>

            <button
              onClick={stopMedrec}
              className="stop-medrec"
              disabled={botStatus == 'STOPPED' ? true : false}
            >
              Stop
            </button>
            <button
              onClick={deleteMedrec}
              className="delete-medrec"
              disabled={botStatus == 'stop' ? true : false}
            >
              Delete
            </button>

            {medrecControlIsloading && (
              <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
            )}
          </div>
        </>
      )}
      <Formik
        initialValues={{
          message: '',
        }}
        // validate={validateLoginForm}
        onSubmit={sendMessageBtn}
        innerRef={(f) => (formRef.current = f)}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="quick-message-input" style={updateColor}>
              <Field
                name="message"
                as="textarea"
                className="message-input-field chat-input"
                onFocus={handleFocus}
                onBlur={handleBlur}
                innerRef={(f) => (inputRef.current = f)}
                onKeyPress={handleKeyPress}
                placeholder="Message ..."
              />

              {/* <div
                className="emoji-picker"
                style={{ position: 'absolute', top: '-300px' }}
              >
                <EmojiPicker open={emojiPicker} onEmojiClick={getEmoji} />
              </div> */}
              <div className="toolbar">
                <div className="toolbar-left">
                  {/* <button
                    type="button"
                    className="btn btn-icon"
                    onClick={handleEmojiOpen}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-face-smile" />
                  </button> */}

                  <Tooltip title="Generate clinical note">
                    <button
                      type="button"
                      className="btn btn-icon chronological-record-button"
                      onClick={handleRefresh}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-notes-medical" />
                    </button>
                  </Tooltip>
                  {/* <Tooltip title="Monitor AI agent">
                    <div className="ai-button-div">
                      {showBeacon && (
                        <div className="aiTooltip">
                          <FontAwesomeIcon
                            icon="fa-solid fa-xmark"
                            className="ai-card-close-btn"
                            onClick={() => setShowBeacon(false)}
                          />
                          AI monitoring view displayed. Use the switch button to
                          change views as needed.
                        </div>
                      )}
                      <button
                        type="button"
                        className={`${showMedrec ? 'medrec-btn-icon-pink' : 'medrec-btn-icon'}`}
                        onClick={() => {
                          handleMedrecBtnClick()
                          showBeacon && setShowBeacon(false)
                        }}
                        style={showMedrec ? styles.AiPink : styles.AiButton}
                      >
                        AI
                      </button>
                    </div>
                  </Tooltip> */}

                  {/* <div className="patientmode-container">
                    <div className="patientmode-wrapper patient-mode-button ai-mode-button">
                      <Tooltip title="CTRL + K">
                        <Switch
                          checked={patientMode}
                          onChange={handleModeChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="secondary"
                        />
                      </Tooltip>
                      <div className="patientmode-title">
                        <p>Direct Message</p>
                        <span>patient</span>
                      </div>
                    </div>
                  </div> */}
                  {/*!LoadingNarrativeGuideNext ? (
                    <Tooltip title="Suggestion for next step">
                      <button
                        type="button"
                        className="suggest-next-step-btn"
                        onClick={() => handleNarrativeGuideNextStep()}
                      >
                        ?
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Please wait for next suggestion">
                      <div className="loading loading-ml">
                        <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                      </div>
                    </Tooltip>
                  )*/}
                </div>
                <div className="send-button">
                  <button type="submit" disabled={isSubmitting} className="">
                    Send
                  </button>
                  <div
                    style={{
                      height: '12px',
                      width: '1px',
                      background: 'white',
                    }}
                  ></div>
                  <img
                    onClick={() => setShowSchedulerModal(true)}
                    className="arrow"
                    src={expandIcon}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div style={styles.poweredBy}>
        <p
          style={{
            textTransform: 'uppercase',
            color: '#a8a8a8',
            fontSize: '10px',
          }}
        >
          Powered By
        </p>
        <img src={grainIcon} alt="grain" style={{ height: '16px' }} />
        <img src={frqLogo} alt="logo" style={{ height: '14px' }} />
      </div>
    </div>
  )
}

export default ModalFooter

const styles = {
  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '3px',
  },

  AiButton: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
    backgroundColor: '#a8a8a8',
  },
  AiPink: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
    backgroundColor: '#cd93d7',
  },
  AiYellow: {
    width: '22px',
    height: '22px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#ffeeaa',
    color: 'gray',
    fontSize: '12px',
    fontFamily: 'Nunito',
    outline: 'none',
    padding: '0',
    marginLeft: '5px',
  },
}
