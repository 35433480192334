import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faA,
    faFaceSmile,
    faLink,
    faMicrophone,
    faPaperPlane,
    faPaperclip,
    faVideo,
    faXmark,
    faNotesMedical,
    faThumbsUp,
    faThumbsDown,
    faComment,
} from "@fortawesome/free-solid-svg-icons";

import "../assets/css/messageModal.css";
import ModalHeader from "./Patient/MessageModal/ModalHeader";
import ModalBody from "./Patient/MessageModal/ModalBody";
import ModalFooter from "./Patient/MessageModal/ModalFooter";
import {
    useGetMedicationRefreshQuery,
    useGetMessagesQuery,
    useGetPatientBotSessionMutation,
    usePatientChatMutation,
    useSendMessagesMutation,
} from "../api/api";

library.add(
    faPaperPlane,
    faA,
    faPaperclip,
    faLink,
    faMicrophone,
    faVideo,
    faFaceSmile,
    faXmark,
    faNotesMedical,
    faThumbsUp,
    faThumbsDown,
    faComment
);

function QuickMessageModal() {

    
    const [patientMode, setPatientMode] = useState(false);
    const [botStatus1, setBotStatus1] = useState("");
    const [showMedrec, setShowMedrec] = useState(false);

    const [notificationCounter, setNotificationCounter] = useState(0);
    const [box, setBox] = useState({
        width: window.innerWidth * 0.90,
        height: window.innerHeight - 90,
    });
    const [messageHistory, setMessageHistory] = useState([]);
    const [medRecMessageHistory, setMedRecMessageHistory] = useState([]);
    const [routerLoading, setRouterLoading] = useState(false);
    const [botStatus, setBotStatus] = useState("STOPPED");

    const sidePanel = useSelector((state) => state.sidePanel.value);

    const pharmacistId = localStorage.getItem("frqLogin");
    const patientId = localStorage.getItem("frqPatientId");
    const [payload, setPayload] = useState({
        pharmacist_user_id: pharmacistId,
        patient_id: patientId,
        filter: "all",
    });

    const pharmacist_id = "560fb893-d6fd-4f28-9c34-12c5d1f8072c";
    const pharmacist_user_id = "55445413-4556-4d10-b735-8b91a8a6812b";
    const patient_id = "a2623098-97a8-4438-9147-8b636844f72a";

    const [messagePayload, setMessagePayload] = useState({
        pharmacist_id: pharmacist_id,
        pharmacist_user_id: pharmacist_user_id,
        patient_id: patient_id,
    });

    const { data: getMedicationRefreshData } = useGetMedicationRefreshQuery({
        patient_id: patient_id,
    });

    const { isLoading: messageIsLoading, data: MessageData } =
        useGetMessagesQuery(messagePayload);
    const [sendMessages, { isLoading, data }] = useSendMessagesMutation();
    const [
        patientChat,
        {
            isLoading: isPatientChatLoading,
            data: patientChatData,
            error: patientChatError,
        },
    ] = usePatientChatMutation();

    useEffect(() => {
        if (!messageIsLoading)
            setMessageHistory(MessageData && MessageData.previous_messages);
        setBotStatus(MessageData && MessageData.session_state);
    }, [MessageData, messageIsLoading]);

    useEffect(() => {
        if (!isLoading && data) {
            setMessageHistory((prev) => [
                ...prev,
                {
                    content: data && data,
                    message_created: new Date(),
                    message_id: Math.random(),
                    sender: "router-bot",
                },
            ]);
        }
    }, [data, isLoading]);

    // const websocketURL =
    //   import.meta.env.VITE_REACT_APP_WEBSOCKET +
    //   '/api/ws/message/' +
    //   pharmacist_id +
    //   '/' +
    //   patient_id

    const websocketURL =
        "wss://controls-uat.frqtechnologies.com" +
        "/api/ws/message/" +
        pharmacist_id +
        "/" +
        patient_id;

    const { lastJsonMessage } = useWebSocket(websocketURL);

    const sendMessageToServer = async (
        pharmacist_id,
        pharmacist_user_id,
        patient_id,
        content
    ) => {
        // const sse = import.meta.env.VITE_REACT_APP_API_URL + '/api/message'
        const sse = "https://controls-uat.frqtechnologies.com" + "/api/message";

        const response = await fetch(sse, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                pharmacist_id: pharmacist_id,
                pharmacist_user_id: pharmacist_user_id,
                patient_id: patient_id,
                content: content,
                mms_url_list: [],
            }),
        });

        const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
        while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            if (value != "") {
                setMessageHistory((prev) => [
                    ...prev,
                    {
                        content: value,
                        message_created: new Date(),
                        message_id: Math.random(),
                        sender: "router-bot",
                    },
                ]);
            }
        }
    };

    // useEffect(() => {
    //   if (!isLoading && !error) {
    //     setMessageHistory(data)
    //   }
    // }, [data, status, isLoading])

    useEffect(() => {
        if (!isPatientChatLoading && !patientChatError) {
            if (patientChatData) {
                if ("detail" in patientChatData) {
                    console.log("detail");
                } else {
                    const parsedData =
                        typeof patientChatData === "string"
                            ? JSON.parse(patientChatData)
                            : patientChatData;

                    // Ensure parsedData is an array
                    const dataToAdd = Array.isArray(parsedData)
                        ? parsedData
                        : [parsedData];

                    // Patient Message received
                    console.log("Patient message received");
                    // Update the message history state
                    setMessageHistory((prev) => [...prev, ...dataToAdd]);
                }
            }
        }
    }, [isPatientChatLoading, patientChatData, patientChatError]);

    const sendMessageBtn = async (values, { resetForm }) => {
        const message = values.message;
        if (message != "") {
            try {
                const pharmacist_id = "560fb893-d6fd-4f28-9c34-12c5d1f8072c";
                const pharmacist_user_id =
                    "55445413-4556-4d10-b735-8b91a8a6812b";
                const patient_id = "a2623098-97a8-4438-9147-8b636844f72a";
                const content = message;
                // mms_url_list: ['string']
                setMessageHistory((prev) => [
                    ...prev,
                    {
                        content: message,
                        message_created: new Date(),
                        message_id: Math.random(),
                        sender: "pharmacist",
                    },
                ]);
                await sendMessageToServer(
                    pharmacist_id,
                    pharmacist_user_id,
                    patient_id,
                    content
                );

                resetForm();
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setBox({ width: window.innerWidth * 0.90, height: window.innerHeight - 90 });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (sidePanel) {
            setBox({ width: window.innerWidth * 0.90, height: window.innerHeight - 90 });
        }
    }, [sidePanel]);

    return (
        <div
            className='quick-message-modal-container full-chat-section'
            style={{ width: box.width + "px", height: box.height + "px" }}
        >
            <ModalHeader
                showMedrec={showMedrec}
                patientMode={patientMode}
                setPayload={setPayload}
                payload={payload}
                botStatus={botStatus1}
            />
            <ModalBody
                messageHistory={messageHistory}
                routerLoading={routerLoading}
                medRecMessageHistory={medRecMessageHistory}
                showMedrec={showMedrec}
                patientMode={patientMode}
                botStatus={botStatus1}
                setBotStatus={setBotStatus1}
            />
            <ModalFooter
                sendMessageBtn={sendMessageBtn}
                setPatientMode={setPatientMode}
                patientMode={patientMode}
                setNotificationCounter={setNotificationCounter}
                botStatus={botStatus}
                routerLoading={routerLoading}
                setBotStatus={setBotStatus}
            />
        </div>
    );
}

export default QuickMessageModal;
