// src/UpdateEncounter.js

import React, { useCallback, useState } from "react";
import { useEncounterWriteback } from "./useEncounterWriteback";

export const UpdateEncounter = ({
  assessmentsGeneralNotes,
  plansGeneralNotes,
  diagnosisCodes,
  procedureCodes,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { canUpdate, doUpdate } = useEncounterWriteback();

  const canUpdateEncounter = useCallback(
    () =>
      canUpdate({
        assessment: {
          generalNotes: true,
          diagnosisCodes: true,
        },
        plan: {
          procedureCodes: true,
          generalNotes: true,
        },
      }),
    [canUpdate]
  );

  const updateEncounter = useCallback(async () => {
    if (canUpdateEncounter()) {
      try {
        setIsUpdating(true);
        await doUpdate({
          assessment: {
            generalNotes: assessmentsGeneralNotes,
            diagnosisCodes,
          },
          plan: {
            procedureCodes,
            generalNotes: plansGeneralNotes,
          },
        });
      } catch (error) {
        console.error("update failed", error);
      } finally {
        setIsUpdating(false);
      }
    }
  }, [
    canUpdateEncounter,
    doUpdate,
    assessmentsGeneralNotes,
    diagnosisCodes,
    procedureCodes,
    plansGeneralNotes,
  ]);

  return (
    <div>
      <button
        disabled={!canUpdateEncounter() || isUpdating}
        onClick={updateEncounter}
      >
        Click to update
      </button>
    </div>
  );
};
