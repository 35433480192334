// src/App.js
import React, { useContext, useEffect, useState } from "react";
import { VimOSContext } from "./VimOSContext";
import { Username } from "./Username";
import { PatientName } from "./PatientName";
import { UpdateEncounter } from "./UpdateEncounter";
import QuickMessageModal from "./components/QuickMessageModal";


const App = () => {
    const vimOS = useContext(VimOSContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (vimOS) {
            // Simulate an asynchronous loading of vimOS
            const checkInitialization = async () => {
                // Replace this with actual async checks if needed
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setIsLoaded(true);
            };

            checkInitialization();
        }
    }, [vimOS]);

    if (!vimOS) {
        return <div>Loadindg Vim SDK...</div>;
    }

    if (!isLoaded) {
        return <div>Loading components...</div>;
    }

    return (
        <div>
          <QuickMessageModal />
            {/*
      <UpdateEncounter
        assessmentsGeneralNotes="Example assessment notes"
        plansGeneralNotes="Example plan notes"
        diagnosisCodes={["D1", "D2"]}
        procedureCodes={["P1", "P2"]}
      /> */}
        </div>
    );
};

export default App;
