// src/useEncounterWriteback.js

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { VimOSContext } from "./VimOSContext";

export const useEncounterWriteback = () => {
  const vimOS = useContext(VimOSContext);
  const [canUpdateFunction, setCanUpdateFunction] = useState(
    vimOS.ehr.resourceUpdater.canUpdateEncounter
  );

  useEffect(() => {
    const cb = () =>
      setCanUpdateFunction(vimOS.ehr.resourceUpdater.canUpdateEncounter);
    vimOS.ehr.subscribe("encounter", cb);
    return () => {
      vimOS.ehr.unsubscribe("encounter", cb);
    };
  }, [vimOS.ehr]);

  return useMemo(
    () => ({
      canUpdate: (payload) => canUpdateFunction(payload).canUpdate,
      doUpdate: vimOS.ehr.resourceUpdater.updateEncounter,
    }),
    [vimOS.ehr.resourceUpdater.updateEncounter, canUpdateFunction]
  );
};
