import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAnglesRight,
  faBell,
  faCircle,
  faFilter,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useGetPatainetInfoQuery } from '../../../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { setSidePanel } from '../../../redux/reducers/sidePanel'
library.add(faXmark, faAnglesRight, faBell, faAnglesRight, faFilter, faCircle)

function ModalHeader({
  notificationCounter,
  showMedrec,
  patientMode,
  setPayload,
  payload,
  botStatus,
  handleNarrativeGuideNextStep,
}) {
  const sidePanel = useSelector((state) => state.sidePanel.value)

 
  const [filter, setFilter] = React.useState(false)
  const [filterValue, setFilterValue] = React.useState('all')

  const dispatch = useDispatch()

  const handleModalClose = () => {
    dispatch(setSidePanel(false))
  }
  const showStatus = () => {
    if (!showMedrec && !patientMode) {
      return 'Messaging AI Assistant'
    } else if (showMedrec && !patientMode) {
      return 'Monitoring AI agent'
    } else if (patientMode) {
      return 'Messaging Patient'
    }
  }
  const styles = {
    Text: {
      color: '#a8a8a8',
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '16px',
      marginTop: '-8px',
    },
    title: {
      color: '#e6d9e8',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: '500',
    },
  }

  const handleFilter = (e) => {
    const value = e.target.value
    localStorage.setItem('frqfilter', value)
    setFilterValue(value)
    setPayload({ ...payload, filter: value })
    handleNarrativeGuideNextStep()
  }

  useEffect(() => {
    if (localStorage.getItem('frqfilter')) {
      setFilterValue(localStorage.getItem('frqfilter'))
    }
  }, [])

  const colors = {
    stop: 'rgb(231, 233, 237)',
    pause: '#ffeeaa',
    resume: '#c3f2cb',
  }

  return (
    <div className="quick-message-modal-header">
      <button className="quick-message-close-btn" onClick={handleModalClose}>
        <FontAwesomeIcon icon="fa-solid fa-angles-right" />
      </button>
      {/* <div style={{ textAlign: 'center' }}>
        <h2 style={styles.title}>{sidePanel && data && data?.name}</h2>
        <p style={styles.Text}>
          {showStatus()}{' '}
          <FontAwesomeIcon
            icon="fa-solid fa-circle"
            style={{ color: colors[botStatus] }}
          />
        </p>
      </div> */}

      <div className="idv">
        <div className="filter">
          <FontAwesomeIcon
            className="filter-icon"
            icon="fa-solid fa-filter"
            onClick={() => setFilter(!filter)}
          />
          {filter && (
            <div className="filter-inputs">
              <form>
                <div className="input-field">
                  <input
                    type="radio"
                    id="all"
                    name="filters"
                    value="all"
                    checked={filterValue === 'all'}
                    onChange={handleFilter}
                  />
                  <label htmlFor="all">All</label>
                </div>
                <div className="input-field">
                  <input
                    type="radio"
                    id="notificaiton"
                    name="filters"
                    value="notificaiton"
                    checked={filterValue === 'notificaiton'}
                    onChange={handleFilter}
                  />
                  <label htmlFor="notificaiton">Notificaiton</label>
                </div>
                <div className="input-field">
                  <input
                    type="radio"
                    id="pharmacist_agent"
                    name="filters"
                    value="pharmacist_agent"
                    checked={filterValue === 'pharmacist_agent'}
                    onChange={handleFilter}
                  />
                  <label htmlFor="pharmacist_agent">AI Assistant</label>
                </div>
                <div className="input-field">
                  <input
                    type="radio"
                    id="pharmacist_patient"
                    name="filters"
                    value="pharmacist_patient"
                    checked={filterValue === 'pharmacist_patient'}
                    onChange={handleFilter}
                  />
                  <label htmlFor="pharmacist_patient">Patient</label>
                </div>
              </form>
            </div>
          )}
        </div>
        {/* <div className="notification quick-message-notification-icon">
          <FontAwesomeIcon icon="fa-solid fa-bell" />
          <span className="notification-counter">{notificationCounter}</span>
        </div> */}
      </div>
    </div>
  )
}

export default ModalHeader
